import basePageFields from '@fragments/pages/basePageFields';

import AvatarFragment from '@fragments/shared/AvatarFragment';
import ContentDynamicSortableFragment from '@fragments/shared/ContentDynamicSortableFragment';
import TopicSummaryFragment from '@fragments/summaries/TopicSummaryFragment';
import RegionSummaryFragment from '@fragments/summaries/RegionSummaryFragment';
import SummaryFragment from '@fragments/summaries/SummaryFragment';
import ImageSummaryFragment from '@fragments/summaries/ImageSummaryFragment';
import SeoFragment from '@fragments/shared/SeoFragment';

export default gql`
    fragment PersonFragment on Person {
        ${basePageFields}
        avatar {
            ...AvatarFragment
        }
        center
        dek
        email
        personDetails {
            about
            affiliations {
                ...SummaryFragment
            }
            personType
            pinnedHeroWork {
                ...SummaryFragment
            }
            education
            languages
            featuredCollections {
                ...SummaryFragment
            }
            featuredTopicsAndRegions {
                id
                isPrimary
                tag {
                    value {
                        ...TopicSummaryFragment
                        ...RegionSummaryFragment
                    }
                }
            }
            featuredContent {
                ...ContentDynamicSortableFragment
            }
        }
        firstName
        hasProfile
        lastName
        middleName
        personTitle
        type
        suffix
        socialNetworks {
            handle
            id
            network
        }
        seo {
            ...SeoFragment
        }
    }
    ${AvatarFragment}
    ${ContentDynamicSortableFragment}
    ${TopicSummaryFragment}
    ${RegionSummaryFragment}
    ${SummaryFragment}
    ${ImageSummaryFragment}
    ${SeoFragment}
`;
