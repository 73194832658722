import { gql } from 'graphql-tag';

export default gql`
    fragment RegionSummaryFragment on Region {
        Region_center: center
        description
        Region___title: title
        type
        path
    }
`;
