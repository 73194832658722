import { gql } from 'graphql-tag';

export default gql`
    fragment TopicSummaryFragment on Topic {
        Topic___center: center
        description
        path
        Topic___title: title
    }
`;
